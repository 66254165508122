<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Impressum</h1>
      </v-col>
      <v-col align-self="end" align="end">
        <v-btn icon to="/">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TrumpIpsum lines="1"></TrumpIpsum>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrumpIpsum from "../components/TrumpIpsum";
export default {
  name: "Imprint",
  components: {TrumpIpsum},
  mounted() {
    document.body.classList.add('imprint')
  },
  destroyed() {
    document.body.classList.remove('imprint')
  }
}
</script>

<style scoped>

</style>
